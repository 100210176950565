import { AuthCredentials } from "@/types/global"

const areCredentialsExpired = (credentials: AuthCredentials) => {
  if (!credentials?.expiry_date) return false
  if (Date.now() >= (credentials.expiry_date || 0)) return true

  return false
}

export default areCredentialsExpired
