"use client"; // Error components must be Client Components

import { Button } from "@hubble/web";
import { Alert, Container, Typography } from "@mui/material";
import { useEffect } from "react";
import ErrorTrackingService from "@/services/ErrorTrackingService";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { getLoginRedirect } from "@/utils/nifty-utils";

const NETWORK_ERROR_MESSAGE = "Network Error";
const NETWORK_ERROR_MESSAGE_TITLE = `A Nifty update is in progress, we’ll be back shortly.`;
const NETWORK_ERROR_MESSAGE_DESCR = `We are currently completing an update and so you may experience issues loading the website. It is also possible that your internet connection is not functioning as expected. Try reloading after a short wait.`;
const AUTH_INVALID = "401";
const MARKETPLACE_RATE_LIMIT = "429";
const MARKETPLACE_RATE_LIMIT_TITLE = "We are experiencing increased demand 😅";
const MARKETPLACE_RATE_LIMIT_DESCR =
  "We will be back shortly, we appreciate your patience. Please try again in a few minutes!";
const DEFAULT_ERROR_TITLE = "Error";
const DEFAULT_ERROR_DESCR =
  "An unexpected error occurred. Try reloading the page.";

export type ErrorProps = {
  error: Error & { digest?: string };
  reset: () => void;
};

export default function Error({ error, reset }: ErrorProps) {
  const router = useRouter();
  const pathname = usePathname();
  const search = useSearchParams();

  const handleRefresh = () => window.location.reload();

  useEffect(() => {
    if (
      !(
        error.message.includes(AUTH_INVALID) ||
        error.message === NETWORK_ERROR_MESSAGE
      )
    ) {
      ErrorTrackingService.captureError(error);
    }

    // redirect 401's to login
    if (error.message.includes(AUTH_INVALID)) {
      router.replace(`/${getLoginRedirect(pathname, search.toString())}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (error.message.includes(MARKETPLACE_RATE_LIMIT)) {
    return (
      <Container sx={{ marginTop: 30 }}>
        <h1>{MARKETPLACE_RATE_LIMIT_TITLE}</h1>
        <p>{MARKETPLACE_RATE_LIMIT_DESCR}</p>
        <Alert severity="error">{error.message}</Alert>
      </Container>
    );
  }

  if (error.message === NETWORK_ERROR_MESSAGE) {
    return (
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <picture>
          <img
            src="https://media.niftygateway.com/image/upload/v1707896294/CompanyPages/watch.png"
            alt="Watch Logo"
            style={{
              marginBottom: "25px",
              height: "60px",
            }}
          />
        </picture>

        <Typography
          variant="h2"
          align="center"
          gutterBottom
          sx={{ padding: "0 20px" }}
        >
          {NETWORK_ERROR_MESSAGE_TITLE}
        </Typography>
        <Typography
          variant="body1"
          align="center"
          gutterBottom
          sx={{
            maxWidth: "840px",
            padding: "20px",
          }}
        >
          {NETWORK_ERROR_MESSAGE_DESCR}
        </Typography>
        <Button.Secondary size="lg" onClick={handleRefresh} cta="Refresh" />
      </Container>
    );
  }

  return (
    <Container sx={{ marginTop: 30 }}>
      <h1>{DEFAULT_ERROR_TITLE}</h1>
      <p>{DEFAULT_ERROR_DESCR}</p>
      <Alert severity="error" sx={{ my: 2 }}>
        {error.message}
      </Alert>
      <Button.Secondary size="lg" onClick={handleRefresh} cta="Refresh" />
    </Container>
  );
}

