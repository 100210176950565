import { POST_AUTH_REDIRECT_PARAM, URL as NIFTY_URL } from "@/app/constants";

export const getRedirectSearch = () => {
  const { search, pathname } = window.location;
  // if url already includes post auth redirect, keep it
  const params = new URLSearchParams(window.location.search);
  if (params.has(POST_AUTH_REDIRECT_PARAM)) {
    return search;
  }
  // if current location is login or signup without redirect location, clear search
  const location = pathname + search;
  if (location === NIFTY_URL.LOGIN || location === NIFTY_URL.SIGNUP) {
    return "";
  }

  // return location to redirect after login or sign up
  params.set(POST_AUTH_REDIRECT_PARAM, window.location.toString())
  return `?${params.toString()}`;
};

/**
 * Returns the redirect URL if the input is either a fully qualified URL on the current domain or subdomain, or a path and/or querystring.
 * Otherwise, returns null.
 * @param rawRedirectUrl A URI-encoded string containing a desired redirect URL, path and/or query string.
 * @returns The redirect URL or null.
 */
export const sanitizeRedirectPath = (rawRedirectUrl: string | null) => {
  if (!rawRedirectUrl) return null;
  try {
    const redirectUrl = new URL(
      // Throws if rawRedirectUrl is not a valid URL, a path with leading ('/) and/or a query string
      decodeURIComponent(rawRedirectUrl.replace(/\+/g, ' ')),
      // If rawRedirectUrl does not contain an origin, the current origin will be used
      window.location.origin
    );
    const niftyHostname = window.location.host.toLowerCase();
    const redirectHostname = redirectUrl.host.toLowerCase();
    // Only redirects to the current domain or subdomains are allowed
    if (redirectHostname === niftyHostname || redirectHostname.endsWith(`.${niftyHostname}`))
      return redirectUrl.toString();
  } catch (e) {
    console.error('Could not parse a redirect URL:', (e as unknown as Error).message)
  }
  return null;
};

export const getLoginRedirect = (pathname: any, search: any) => {
  const sanitizeLocation = new URL(sanitizeRedirectPath(pathname + search) ?? '/');
  return `${NIFTY_URL.LOGIN}/?${POST_AUTH_REDIRECT_PARAM}=${sanitizeLocation.pathname}${sanitizeLocation.search}`;
};

export const getSignupRedirect = (pathname: any, search: any) => {
  const sanitizeLocation = new URL(sanitizeRedirectPath(pathname + search) ?? '/');
  return `${NIFTY_URL.SIGNUP}/?${POST_AUTH_REDIRECT_PARAM}=${sanitizeLocation.pathname}${sanitizeLocation.search}`;
};
