import { datadogRum } from '@datadog/browser-rum'
import areCredentialsExpired from '@/utils/are-credentials-expired'
import packageJson from '../../package.json'
import { AuthCredentials } from '@/types/global'

const envProd = process.env.NEXT_PUBLIC_REACT_APP_WEB3_CHAIN_ENVIRONMENT === 'production'
const { version } = packageJson
const denyUrls = [
  /\/\/([^/]*\.)*stripe/,
  /\/\/([^/]*\.)*facebook/,
  /\/\/([^/]*\.)*google/,
  /\/\/([^/]*\.)*twitter.com\//,
  /\/\/([^/]*\.)*fullstory.com\//,
  /\/\/([^/]*\.)*mixpanel.com\//,
  /\/\/([^/]*\.)*zendesk.com\//,
  /\/\/([^/]*\.)*zdassets.com\//,
  /\/\/([^/]*\.)*snapchat.com\//,
  /\/\/([^/]*\.)*static.zdassets.com\//,
]
const allowUrls = [/https:\/\/([^/]*\.)*niftygateway\.com\//]

const checkBrowserStorage = () => {
  try {
    const credentials = JSON.parse(
      (sessionStorage.getItem('auth.credentials') ||
        localStorage.getItem('auth.credentials')) as string,
    )

    if (credentials === null) {
      return {}
    }

    return credentials
  } catch (err) {
    return {}
  }
}

const checkIfUserLoggedIn = () => {
  const credentials: AuthCredentials = checkBrowserStorage()

  return credentials?.access_token !== undefined &&
    credentials?.expiry_date !== undefined
    ? !areCredentialsExpired(credentials)
    : false
}

class ErrorTrackingService {
  constructor() {
    const userLoggedIn = checkIfUserLoggedIn()
    if (envProd) {
      datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_REACT_APP_DATADOG_APP_ID as string,
        clientToken: process.env.NEXT_PUBLIC_REACT_APP_DATADOG_CLIENT_TOKEN as string,
        site: 'datadoghq.com',
        service: 'nifty-gateway',
        version,
        env: process.env.NEXT_PUBLIC_REACT_APP_WEB3_CHAIN_ENVIRONMENT,
        sampleRate: userLoggedIn ? 30 : 20,
        defaultPrivacyLevel: 'mask-user-input',
        excludedActivityUrls: denyUrls,
        allowedTracingOrigins: allowUrls,
      })
    }
  }

  captureError(err: Error, context: any = {}): void {
    datadogRum.addError(err, context)
  }

  captureMoreInfo(msg: string, info: {}): void {
    datadogRum.addAction(msg, info)
  }
}

const singleton = Object.freeze(new ErrorTrackingService())

export default singleton
